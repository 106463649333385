import * as React from 'preact/compat';
import {Text} from 'preact-i18n';
import {ItemCategory} from '../shared';

type HeaderLinkProps = {
    category: ItemCategory,
    isSelected: boolean,
    nbHits: number,
    onClick: () => void
}

const HeaderLink: React.FC<HeaderLinkProps> = (
    {
        category,
        isSelected,
        nbHits,
        onClick
    }) => {
    return (
        <a className={isSelected && "active"} onClick={onClick}>
            <Text id={`search.resource-title.${category}`}></Text> <span>{nbHits}</span>
        </a>
    );
}

export default HeaderLink;
