import * as React from 'preact/compat';
import {Text,useText} from "preact-i18n";
import {ItemCategory} from '../shared';
import IconFilterBold from '@devolutions/icons/react/FilterBold';

export type ResultsHeaderProps = {
    category: ItemCategory;
    hasFilters: boolean;
    showFilters: boolean;
    onToggleFilters: () => void;
}

const ResultsHeader: React.FC<ResultsHeaderProps> = (
    {
        category,
        hasFilters,
        onToggleFilters,
        showFilters
    }
) => {
    return (
        <div className="search-content_header">
            <span><Text id={`search.${category}.item-name`}></Text></span>
            {hasFilters &&
                <button className="toggle-filter-button" title={useText('action.show-hide-options')['show-hide-options']} onClick={onToggleFilters}>
                    <IconFilterBold/>
                </button>
            }
        </div>
    );
}

export default ResultsHeader;
