import * as React from 'preact/compat';
import Category from "./category";
import {SearchClient} from 'algoliasearch';
import {ResultsHeaderProps} from '../structure/results-header';
import {OptionsPanel, truncateItem} from '../shared';
import {SortBy} from '../sortBy';
import {PlatformRefinementList, SoftwareRefinementList} from '../refinementLists';
import {useCallback} from 'preact/compat';

type ForumCategoryProps = {
    query: string;
    searchClient: SearchClient;
    isSelected: boolean;
    onHitsChange: (hits: number) => void;
    nbHits: number;
    dateLocale: Locale;
    showFilters: boolean;
    header: Omit<ResultsHeaderProps, "category">;
    sortBy: string;
    onSortByChange: (sortBy: string) => void;
}

const ForumCategory: React.FC<ForumCategoryProps> = (
    {
        query,
        searchClient,
        isSelected,
        onHitsChange,
        nbHits,
        dateLocale,
        showFilters,
        header,
        sortBy,
        onSortByChange,
    }
) => {
    const transformItems = useCallback((items) => {
        return items.map(item => truncateItem(item, 'text'));
    }, []);

    return (
        <Category category="forum"
                  facetingAfterDistinct={true}
                  index='forum'
                  query={query}
                  searchClient={searchClient}
                  isSelected={isSelected}
                  dateLocale={dateLocale}
                  header={header}
                  showFilters={showFilters}
                  transformItems={transformItems}
                  nbHits={nbHits}
                  onHitsChange={onHitsChange}>
            <OptionsPanel title={'search.sort-by'}>
                <SortBy sortBy={sortBy} defaultIndex='forum' onSortByChange={onSortByChange}/>
            </OptionsPanel>

            <OptionsPanel title={'search.software.filter-by'}>
                <SoftwareRefinementList/>
            </OptionsPanel>

            <OptionsPanel title={'search.platform.filter-by'}>
                <PlatformRefinementList/>
            </OptionsPanel>
        </Category>
    );
}

export default ForumCategory;
