import * as React from 'preact/compat';
import {useEffect, useMemo, useState} from 'preact/compat';
import {useSortBy} from 'react-instantsearch-hooks-web';
import {useText} from 'preact-i18n';

export type SortByProps = {
    sortBy: string;
    defaultIndex: string;
    onSortByChange: (sortBy: string) => void;
}

export const SortBy: React.FC<SortByProps> = (
    {
        sortBy,
        defaultIndex,
        onSortByChange
    }) => {
    const relevanceText = useText('search.relevance').relevance;
    const dateText = useText('search.date').date;

    const sortByMappings = {
        'relevance': defaultIndex,
        'date': `${defaultIndex}_published_date`
    }

    const isValidSortBy = sortBy && ['relevance', 'date'].includes(sortBy);
    const [selectedValue, setSelectedValue] = useState(isValidSortBy ? sortBy : 'relevance');
    useEffect(() => {
        setSelectedValue(isValidSortBy ? sortBy : 'relevance');
    }, [isValidSortBy, sortBy]);

    useEffect(() => {
        refine(sortByMappings[selectedValue]);
    }, [selectedValue]);

    const items: any[] = useMemo(() => {
        return [
            {label: relevanceText, value: 'relevance'},
            {label: dateText, value: 'date'},
        ];
    }, [relevanceText, dateText]);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        onSortByChange(event.target.value);
    }

    const {
        options,
        refine,
    } = useSortBy({
        items
    });

    return (
        <div className="ais-SortBy">
            <select className="ais-SortBy-select" value={selectedValue} onChange={handleChange}>
                {options.map(option =>
                    <option key={option.value} className="ais-SortBy-option"
                            value={option.value}>{option.label}</option>
                )}
            </select>
        </div>
    );
}
