import { useRefinementList } from 'react-instantsearch-hooks-web';
import * as React from 'preact/compat';
import {Text} from 'preact-i18n';

export const DocumentTypesRefinementList: React.FC<{}> = () => {

    const { items, refine } = useRefinementList({
        attribute: 'type'
    });

    items.sort((x, y) => y.count - x.count);

    return (
        <div class="ais-RefinementList">
            <ul class="ais-RefinementList-list">
                {items.map((item, index) => {
                    return (
                        <li key={index}>
                            <label>
                                <input type="checkbox" onChange={() => refine(item.value)}/>
                                <span><Text id={'search.documents.types.' + item.label.toLowerCase()}></Text></span>
                            </label>
                            <span class="result-counts"><span class="pastille">{item.count || '0'}</span></span>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}
