import * as React from "preact/compat";
import {useEffect, useState} from "preact/compat";
import {useInstantSearch} from "react-instantsearch-hooks-web";

export const StateResults: React.FC<{
    query: string,
    onResultChange: (totalHits: number) => void;
}> = ({query, onResultChange}) => {
    const {results} = useInstantSearch();
    const [isFirstPass, setIsFirstPass] = useState(true);

    useEffect(() => {
        if (isFirstPass) {
            setIsFirstPass(false);
        } else if (query === results.query) {
            onResultChange(results.nbHits);
        }
    }, [query, results])

    return <></>;
};
