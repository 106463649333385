import * as React from 'preact/compat';
import Category from "./category";
import {SearchClient} from 'algoliasearch';
import {ResultsHeaderProps} from '../structure/results-header';
import {OptionsPanel, truncateItem} from '../shared';
import {BlogCategoriesRefinementList} from '../refinementLists';
import {useCallback, useEffect, useState} from 'preact/compat';

type BlogCategoryProps = {
    locale: string;
    query: string;
    searchClient: SearchClient;
    isSelected: boolean;
    onHitsChange: (hits: number) => void;
    nbHits: number;
    dateLocale: Locale;
    showFilters: boolean;
    header: Omit<ResultsHeaderProps, 'category'>
}

const BlogCategory: React.FC<BlogCategoryProps> = (
    {
        locale,
        query,
        searchClient,
        isSelected,
        onHitsChange,
        nbHits,
        dateLocale,
        showFilters,
        header,
    }
) => {
    const transformItems = useCallback((items) => {
        return items.map(item => truncateItem(item, 'text'));
    }, []);

    const [index] = useState(`blog-${locale === 'de' ? 'en' : locale}`);

    return (
        <Category category="blog"
                  facetingAfterDistinct={true}
                  index={index}
                  query={query}
                  searchClient={searchClient}
                  isSelected={isSelected}
                  dateLocale={dateLocale}
                  header={header}
                  showFilters={showFilters}
                  transformItems={transformItems}
                  nbHits={nbHits}
                  onHitsChange={onHitsChange}>
            <OptionsPanel title={'search.categories.filter-by'}>
                <BlogCategoriesRefinementList/>
            </OptionsPanel>
        </Category>
    );
}

export default BlogCategory;
