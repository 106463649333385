import * as React from 'preact/compat';
import {useEffect, useRef, useState} from 'preact/compat';
import registerComponent from 'preact-custom-element';

import styles from '../../assets/scss/main.scss';
import {de, enUS, fr} from 'date-fns/locale';
import {ItemCategory} from './shared';

import translationsEN from '../../assets/i18n/en.json';
import translationsFR from '../../assets/i18n/fr.json';
import {IntlProvider} from 'preact-i18n';
import {SupportedLanguage} from './shared/types';
import algoliasearch from 'algoliasearch';
import ForumCategory from './category/forum-category';
import SearchHeader from './structure/search-header';
import {ResultsHeaderProps} from './structure/results-header';
import DocsCategory from './category/docs-category';
import BlogCategory from './category/blog-category';
import DocumentCategory from './category/document-category';
import {useOnClickOutside} from './hooks/click-outside-hook';
import {MultipleQueriesOptions, MultipleQueriesQuery} from '@algolia/client-search';
import {RequestOptions} from '@algolia/transporter';

const algoliaClient = algoliasearch('LNWCTDWZS0', '6e67c0e4bb7746718584f2575a584e33');

const searchClient: any = {
    search(queries: readonly MultipleQueriesQuery[], requestOptions?: RequestOptions & MultipleQueriesOptions) {
        if (queries[0].params.query.length < 3) {
            return Promise.resolve({
                results: queries.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0
                }))
            });
        }
        return algoliaClient.search(queries, requestOptions);
    },
};

export type DevolutionsSearchProps = {
    query?: string;
    sortBy?: string;
    locale?: string;
    selectedCategory?: ItemCategory;
    opened?: string;
    darkTheme?: string;
}

export const DevolutionsSearch: React.FC<DevolutionsSearchProps> = (
    {
        query: queryProp,
        sortBy: sortByProp,
        locale: localeProp,
        selectedCategory: selectedCategoryProp,
        opened: openedProp,
        darkTheme: darkThemeProp,
    }) => {
    const ref = useRef<HTMLDivElement>();
    useOnClickOutside(ref, () => closeDialog());

    const allowedLocales = ['en', 'fr', 'de'];

    const [initialLoading, setInitialLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setInitialLoading(false);
        }, 250);
    }, []);

    const parseDarkThemeProp = () => {
        try {
            return JSON.parse(darkThemeProp);
        } catch {
            return false;
        }
    };

    const [darkTheme, setDarkTheme] = useState<boolean>(parseDarkThemeProp() || false);
    useEffect(() => {
        setDarkTheme(parseDarkThemeProp());
    }, [darkThemeProp]);

    const [sortBy, setSortBy] = useState<string | undefined>(sortByProp);
    useEffect(() => {
        setSortBy(sortByProp);
    }, [sortByProp]);

    const parseOpenedProp = () => {
        try {
            return JSON.parse(openedProp);
        } catch {
            return false;
        }
    };

    const [opened, setOpened] = useState<boolean>(parseOpenedProp() || false);
    useEffect(() => {
        setOpened(parseOpenedProp());
    }, [openedProp]);

    const [query, setQuery] = useState(queryProp || '');
    useEffect(() => {
        setTimeout(() => {
            setQuery(queryProp || '');
        }, initialLoading ? 500 : 0);
    }, [queryProp]);

    const [selectedCategory, setSelectedCategory] = useState<ItemCategory>(selectedCategoryProp || 'forum');
    useEffect(() => {
        setSelectedCategory(selectedCategoryProp || 'forum');
    }, [selectedCategoryProp]);

    const [locale, setLocale] = useState(localeProp || 'en');
    useEffect(() => {
        const trimmedLocale = localeProp ? localeProp.substring(0, 2) : 'en';
        const localeOrDefault = (allowedLocales.includes(trimmedLocale) ? trimmedLocale : 'en') as SupportedLanguage;

        setLocale(localeOrDefault);

        if (localeOrDefault === 'fr') {
            setTranslations(translationsFR as any);
            setDateLocale(fr);
        } else if (localeOrDefault === 'de') {
            setTranslations(translationsEN as any);
            setDateLocale(de);
        } else {
            setTranslations(translationsEN as any);
            setDateLocale(enUS);
        }

    }, [localeProp]);

    const [translations, setTranslations] = useState(translationsEN);
    const [dateLocale, setDateLocale] = useState(enUS);
    const [showFilters, setShowFilters] = useState(true);
    const [hits, setHits] = useState<{ [category: string]: number }>({
        'forum': 0,
        'docs': 0,
        'blog': 0,
        'documents': 0
    });

    const updateQuery = (query: string) => {
        if (!query || query.length < 3) {
            updateHits('forum', 0);
            updateHits('docs', 0);
            updateHits('blog', 0);
            updateHits('documents', 0);
        }
        setQuery(query || '');

        const event = new CustomEvent('devolutions-search:querychanged', {detail: {query}});
        document.dispatchEvent(event);
    };

    const updateHits = (category: ItemCategory, total: number) => setHits(state => ({
        ...state,
        [category]: total
    }));

    const toggleShowFilters = () => {
        setShowFilters(!showFilters);
    };

    const closeDialog = () => {
        if (!opened) {
            return;
        }

        setOpened(false);

        const event = new Event('devolutions-search:closed');
        document.dispatchEvent(event);
    };

    const sortChanged = (sortBy: string) => {
        const event = new CustomEvent('devolutions-search:sortbychanged', {detail: {sortBy}});
        document.dispatchEvent(event);
    };

    const commonHeader: Omit<ResultsHeaderProps, 'category'> = {
        onToggleFilters: () => {
            toggleShowFilters();

        },
        showFilters: showFilters,
        hasFilters: true
    };

    return (
        <IntlProvider definition={translations}>
            <style>{styles}</style>
            <div className={`devolutions-search ${opened ? 'devolutions-search--opened' : ''}`}>
                <div ref={ref} className={`search-page ${!initialLoading ? 'search-page--visible' : ''} ${darkTheme ? 'dark-theme' : ''}`}>
                    <SearchHeader modalOpened={opened}
                                  nbHits={hits}
                                  query={query}
                                  onQueryChange={updateQuery}
                                  onClose={closeDialog}
                                  selectedCategory={selectedCategory}
                                  onSelectCategory={setSelectedCategory}/>
                    <ForumCategory query={query}
                                   searchClient={searchClient}
                                   dateLocale={dateLocale}
                                   onHitsChange={(nbHits) => updateHits('forum', nbHits)}
                                   isSelected={selectedCategory === 'forum'}
                                   nbHits={hits['forum']}
                                   showFilters={showFilters}
                                   header={commonHeader}
                                   onSortByChange={sortChanged}
                                   sortBy={sortBy}/>
                    <DocsCategory locale={locale}
                                  query={query}
                                  searchClient={searchClient}
                                  dateLocale={dateLocale}
                                  onHitsChange={(nbHits) => updateHits('docs', nbHits)}
                                  isSelected={selectedCategory === 'docs'}
                                  nbHits={hits['docs']}
                                  showFilters={showFilters}
                                  header={commonHeader}/>

                    <BlogCategory locale={locale}
                                  query={query}
                                  searchClient={searchClient}
                                  dateLocale={dateLocale}
                                  onHitsChange={(nbHits) => updateHits('blog', nbHits)}
                                  isSelected={selectedCategory === 'blog'}
                                  nbHits={hits['blog']}
                                  showFilters={showFilters}
                                  header={commonHeader}/>

                    <DocumentCategory locale={locale}
                                      query={query}
                                      searchClient={searchClient}
                                      dateLocale={dateLocale}
                                      onHitsChange={(nbHits) => updateHits('documents', nbHits)}
                                      isSelected={selectedCategory === 'documents'}
                                      nbHits={hits['documents']}
                                      showFilters={showFilters}
                                      header={commonHeader}/>
                </div>
            </div>
        </IntlProvider>
    );
};

registerComponent(DevolutionsSearch, 'devolutions-search', ['query', 'sort-by', 'locale', 'selected-category', 'opened', 'dark-theme'], {shadow: true});
