import * as React from 'preact/compat';
import {Hit} from 'instantsearch.js/es/types';
import {LogoMapper} from '../../shared';
import Highlighter from "react-highlight-words";
import {Text} from 'preact-i18n';

export const DocumentsHit: React.FC<{
    searchWords: string[],
    hit: Hit,
    dateLocale: Locale
}> = (
    {
        searchWords,
        hit,
        dateLocale
    }) => {

    const getPdfUrl = () => {
        if (hit.url && hit.url.length > 0) {
            return hit.url;
        }

        return `https://cdn.devolutions.net/documents/${hit.objectID}.pdf`;
    }

    const getImageUrl = () => {
        if (hit.imageUrl.length > 0) {
            return hit.imageUrl;
        }

        return `https://webdevolutions.azureedge.net/images/documents/${hit.objectID}.jpg`;
    }

    return (
        <div class="hit document">
            <div class="document__details">
                <a class="document__image" target="_blank" href={getPdfUrl()}>
                    { hit.hasImage && <img src={getImageUrl()} alt="pdf image" /> }
                </a>

                <div class="document__section">
                    <a target="_blank" href={getPdfUrl()} class="document__title">
                        <Highlighter
                            highlightClassName="ais-Highlight-highlighted"
                            searchWords={searchWords}
                            autoEscape={true}
                            textToHighlight={hit.title}
                        />
                    </a>

                    <div class="document__type">
                        <Text id={`[search.documents.types.${hit.type}]`}></Text>
                    </div>

                    {hit.description?.length > 0 &&
                        <div class="document__description">
                            <Highlighter
                                highlightClassName="ais-Highlight-highlighted"
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={hit.description}
                            />
                        </div>
                    }
                </div>
            </div>

            <div class="document__infos">
                {hit.products?.map((product: string) => LogoMapper.getClassNameFromSoftwareOrPlatform(product))}
            </div>
        </div>
    )
};
