import { useRefinementList } from 'react-instantsearch-hooks-web';
import * as React from 'preact/compat';
import {Text} from 'preact-i18n';
import { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import {LogoMapper} from '../shared/logo-mapper';

export const SoftwareRefinementList: React.FC<{ refinementAttribute?: string }> = ({ refinementAttribute }) => {

    const { items, refine } = useRefinementList({
        attribute: refinementAttribute || 'software'
    });

    const filterItems = (items: RefinementListItem[]) => {
        return items.filter(item => !['pvm','wayk'].includes(item.label));
    }

    items.sort((x, y) => y.count - x.count);

    return (
        <>
            <div class="ais-RefinementList">
                <ul class="ais-RefinementList-list">
                    {filterItems(items).map((item, index)=>{
                        return (
                            <li key={index}>
                            <label>
                                <input type="checkbox" onChange={() => refine(item.value)}/>
                                {LogoMapper.getClassNameFromSoftwareOrPlatform(item.value)}
                                <span><Text id={'search.software.' + item.label.toLowerCase()}></Text></span>
                                </label>
                                <span class="result-counts"><span class="pastille">{item.count || '0'}</span></span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    );
}
