import * as React from 'preact/compat';
import {PropsWithChildren} from 'preact/compat';
import {Text} from 'preact-i18n';

export const OptionsPanel: React.FC<PropsWithChildren<{title: string}>> = ({ title, children }) => {
    return (
        <div class="options-panel">
            <div className="options-panel__title"><Text id={title}></Text></div>
            {children}
        </div>
    );
}
