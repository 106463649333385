import * as React from 'preact/compat';
import Category from "./category";
import {SearchClient} from 'algoliasearch';
import {ResultsHeaderProps} from '../structure/results-header';
import {OptionsPanel, truncateItem} from '../shared';
import {
    DocumentTypesRefinementList,
    SoftwareRefinementList
} from '../refinementLists';
import {useCallback, useEffect, useState} from 'preact/compat';

type DocumentCategoryProps = {
    locale: string;
    query: string;
    searchClient: SearchClient;
    isSelected: boolean;
    onHitsChange: (hits: number) => void;
    nbHits: number;
    dateLocale: Locale;
    showFilters: boolean;
    header: Omit<ResultsHeaderProps, "category">
}

const DocumentCategory: React.FC<DocumentCategoryProps> = (
    {
        locale,
        query,
        searchClient,
        isSelected,
        onHitsChange,
        nbHits,
        dateLocale,
        showFilters,
        header,
    }
) => {
    const transformItems = useCallback((items) => {
        return items.map(item => truncateItem(item, 'description'));
    }, []);

    const [index, setIndex] = useState(`documents-${locale}`);
    useEffect(() => {
        setIndex(`documents-${locale}`);
    }, [locale]);

    return (
        <Category category="documents"
                  facetingAfterDistinct={true}
                  index={index}
                  query={query}
                  searchClient={searchClient}
                  isSelected={isSelected}
                  dateLocale={dateLocale}
                  header={header}
                  showFilters={showFilters}
                  transformItems={transformItems}
                  nbHits={nbHits}
                  onHitsChange={onHitsChange}>
            <OptionsPanel title={'search.software.filter-by'}>
                <SoftwareRefinementList refinementAttribute={'products'}/>
            </OptionsPanel>

            <OptionsPanel title={'search.documents.filter-by-types'}>
                <DocumentTypesRefinementList/>
            </OptionsPanel>
        </Category>
    );
}

export default DocumentCategory;
