import * as React from 'preact/compat';
import {Hit, TransformItems} from 'instantsearch.js/es/types';
import {useInfiniteHits} from 'react-instantsearch-hooks-web';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {ItemCategory} from '../shared';
import {DocumentsHit} from './types/documents-hit';
import {ForumHit} from './types/forum-hit';
import {BlogHit} from './types/blog-hit';
import {DocsHit} from './types/docs-hit';
import {useEffect, useState} from 'preact/compat';

export const InfiniteHits: React.FC<{
    query: string,
    category: ItemCategory,
    dateLocale: Locale,
    transformItems?: TransformItems<Hit>
}> = (
    {
        query: queryProp,
        category,
        dateLocale,
        transformItems
    }) => {

    const {hits, isLastPage, showMore} = useInfiniteHits({
        transformItems,
    });

    const [searchWords, setSearchWords] = useState<string[]>([]);
    useEffect(() => {
        const q = queryProp.replace(/\"/gm, '');
        setSearchWords(q.split(' '));
    }, [queryProp]);

    const [sentryRef, {rootRef}] = useInfiniteScroll({
        loading: false,
        hasNextPage: !isLastPage,
        onLoadMore: showMore,
        disabled: false,
        rootMargin: '0px 0px 400px 0px',
    });

    const getTemplateByCategory = (category: ItemCategory, hit: Hit, dateLocale: Locale) => {
        switch (category) {
            case 'forum':
                return <ForumHit key={hit.objectID} searchWords={searchWords} hit={hit} dateLocale={dateLocale}/>;
            case 'documents':
                return <DocumentsHit key={hit.objectID} searchWords={searchWords} hit={hit} dateLocale={dateLocale}/>;
            case 'blog':
                return <BlogHit key={hit.objectID} searchWords={searchWords} hit={hit} dateLocale={dateLocale}/>;
            case 'docs':
                return <DocsHit key={hit.objectID} searchWords={searchWords} hit={hit}/>;
            default:
                return new Error('Not Implemented Template');
        }
    }

    return (
         <div class="hits" ref={rootRef}>
            {hits.map(hit => getTemplateByCategory(category, hit, dateLocale))}
            {(!isLastPage) && (
                <div class="loadMoreRef" ref={sentryRef}></div>
            )}
         </div>

    );
};
