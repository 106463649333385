import * as React from 'preact/compat';
import {Configure, InstantSearch} from "react-instantsearch-hooks-web";
import {PropsWithChildren} from "preact/compat";
import {Text} from "preact-i18n";
import {SearchClient} from 'algoliasearch';
import {ItemCategory} from '../shared';
import ResultsHeader, {ResultsHeaderProps} from '../structure/results-header';
import {StateResults} from '../structure/state-result';
import {InfiniteHits} from '../hits';

interface CategoryProps {
    category: ItemCategory;
    dateLocale: any;
    index: string;
    query: string;
    nbHits: number;
    onHitsChange: (total: number) => void;
    searchClient: SearchClient;
    facetingAfterDistinct: boolean;
    isSelected: boolean;
    showFilters: boolean;
    transformItems: (item: any[]) => any[];
    header: Omit<ResultsHeaderProps, "category">;
}

const Category: React.FC<PropsWithChildren<CategoryProps>> = (
    {
        category,
        dateLocale,
        index,
        query,
        nbHits,
        onHitsChange,
        searchClient,
        facetingAfterDistinct,
        isSelected,
        showFilters,
        transformItems,
        header,
        children,
    }
) => {
    const onReceivedResults = (nbHits: number) => {
        onHitsChange(nbHits);
    }

    return (
        <InstantSearch indexName={index} searchClient={searchClient}>
             <Configure hitsPerPage={100}
                       query={query}
                       advancedSyntax={true}
                       distinct={true}
                       attributesToHighlight={[]}
                       page={0}
                       highlightPreTag={'__ais-highlight__'}
                       highlightPostTag={'__/ais-highlight__'}
                       facetingAfterDistinct={facetingAfterDistinct}></Configure>
            <StateResults query={query}
                          onResultChange={onReceivedResults}/>
            <div className={`content-wrapper ${isSelected ? 'content-wrapper__visible' : ''}`}>
                <div class="search-content">
                    <ResultsHeader category={category}
                                   {...header}/>

                    {nbHits > 0 &&
                        <InfiniteHits category={category}
                                      dateLocale={dateLocale}
                                      query={query}
                                      transformItems={transformItems}/>
                    }

                    {nbHits === 0 && query.length >= 3 && (
                        <div className="search-content_noresult"><Text id="search.message.empty-result" fields={{ query }}></Text></div>
                    )}

                    {nbHits === 0 && query.length < 3 && (
                        <div className="search-content_noresult"><Text id="search.message.enter-query"></Text></div>
                    )}
                </div>
                {children &&
                    <div className={`search-content-options__container ${!showFilters ? 'search-content-options__container--hidden' : ''}`}>
                        <div class="search-content-options">
                            {children}
                        </div>
                    </div>
                }
            </div>
        </InstantSearch>
    );
}

export default Category;
