import * as React from 'preact/compat';
import {Hit} from 'instantsearch.js/es/types';
import {format, formatDistanceToNow, parseISO} from 'date-fns';
import {LogoMapper} from '../../shared';
import Highlighter from 'react-highlight-words';
import IconTopic from '@devolutions/icons/react/Topic';
import IconView from '@devolutions/icons/react/View';
import IconEntrySampleComment from '@devolutions/icons/react/EntrySampleComment';
import IconEntryOtp from '@devolutions/icons/react/EntryOtp';

export const ForumHit: React.FC<{
    searchWords: string[],
    hit: Hit,
    dateLocale: Locale
}> = (
    {
        searchWords,
        hit,
        dateLocale
    }) => {

    const formatDate = (date) => {
        return format(parseISO(date), "PPP", { locale: dateLocale });
    }

    const formatDateFromNow = (date) => {
        return formatDistanceToNow(parseISO(date), { locale: dateLocale });
    }

    return (
        <div className="hit forum">
            <a target="_blank" href={hit.url} className="forum__title title">
                <Highlighter
                    highlightClassName="ais-Highlight-highlighted"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={hit.title}
                />
            </a>

            <div className="forum__subtitle subtitle">
                <IconTopic/> {hit.section} • {formatDate(hit.published_date)}
            </div>

            <div className="forum__details">
                {hit.software && LogoMapper.getClassNameFromSoftwareOrPlatform(hit.software)}
                {hit.platform && LogoMapper.getClassNameFromSoftwareOrPlatform(hit.platform)}
                {hit.avatar &&
                    <img className="avatar small result-image" title={hit.username} alt={hit.username}
                         src={hit.avatar} width="24" height="24"/>}
            </div>

            <div class="forum__content">
                <Highlighter
                    highlightClassName="ais-Highlight-highlighted"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={hit.text}
                />
            </div>

            <div className="forum__footer">
                <div className="stats-group">
                    <IconView/>
                    <span>{hit.view_count || '0'}</span>
                </div>

                <div className="stats-group">
                    <IconEntrySampleComment/>
                    <span>{hit.replies_count || '0'}</span>
                </div>

                <div className="stats-group">
                    <IconEntryOtp/>
                    <span>{formatDateFromNow(hit.published_date)}</span>
                </div>
            </div>
        </div>
    )
};
