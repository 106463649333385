import * as React from 'preact/compat';
import HeaderLink from './header-link';
import {useEffect, useMemo, useRef} from 'preact/compat';
import {useDebouncedCallback} from 'use-debounce';
import {ItemCategory} from '../shared';
import {useText} from 'preact-i18n';
import IconSearch from '@devolutions/icons/react/Search';
import IconClose from '@devolutions/icons/react/Close';
import IconCloseWindow from '@devolutions/icons/react/CloseWindow';

export type SearchHeaderProps = {
    modalOpened: boolean;
    query: string;
    nbHits: { [category: string]: number };
    selectedCategory: ItemCategory;
    onSelectCategory: (category: ItemCategory) => void;
    onQueryChange: (query: string) => void;
    onClose: () => void;
}

const SearchHeader: React.FC<SearchHeaderProps> = (
    {
        modalOpened,
        query,
        nbHits,
        selectedCategory,
        onSelectCategory,
        onQueryChange,
        onClose
    }
) => {
    const searchInput = useRef<HTMLInputElement>()
    useEffect(() => {
        if (searchInput.current && modalOpened) {
            searchInput.current.focus()
        }
    }, [modalOpened])

    const debounced = useDebouncedCallback(
        (value) => {
            if (onQueryChange) {
                onQueryChange(value);
            }
        },
        750
    );

    const placeholder = useMemo(() => {
        return useText(`search.input-placeholder.${selectedCategory}`)[selectedCategory];
    }, [selectedCategory]);

    const clearQuery = () => {
        searchInput.current.value = '';
        searchInput.current.focus();
        onQueryChange('');
    }

    return (
        <div className="search-header">
            <button className="close-dialog-button" onClick={onClose}>
                <IconClose/>
            </button>
            <div className="search-header_content">
                <div className="tab-bar">
                    <HeaderLink category="forum" nbHits={nbHits['forum']} isSelected={selectedCategory === 'forum'} onClick={() => onSelectCategory('forum')}/>
                    <HeaderLink category="docs" nbHits={nbHits['docs']} isSelected={selectedCategory === 'docs'} onClick={() => onSelectCategory('docs')}/>
                    <HeaderLink category="blog" nbHits={nbHits['blog']} isSelected={selectedCategory === 'blog'} onClick={() => onSelectCategory('blog')}/>
                    <HeaderLink category="documents" nbHits={nbHits['documents']} isSelected={selectedCategory === 'documents'} onClick={() => onSelectCategory('documents')}/>
                </div>
                <div className="dvl-input-search">
                    <IconSearch/>
                    <input ref={searchInput} type="text" defaultValue={query} spellCheck={false} placeholder={placeholder}
                           onChange={(e) => debounced((e.target as any).value)}/>
                    {query &&
                        <button onClick={clearQuery} className="search-header__input-clear">
                            <IconCloseWindow/>
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default SearchHeader;
