export const truncateItem = (item: any, property: string) => {
    if (item[property]) {
        item[property] = truncate(item[property], ['500']);
    }

    return item;
}

export const truncate = (value: string, args: string[]) => {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
}
