import {FunctionalComponent, h, VNode} from 'preact';
import IconWindows from '@devolutions/icons/react/Windows';
import IconApple from '@devolutions/icons/react/Apple';
import IconAndroid from '@devolutions/icons/react/Android';
import IconIos from '@devolutions/icons/react/Ios';
import IconLinux from '@devolutions/icons/react/Linux';
import IconLogoRdm from '@devolutions/icons/react/LogoRdm';
import IconLogoPvm from '@devolutions/icons/react/LogoPvm';
import IconLogoDps from '@devolutions/icons/react/LogoDps';
import IconLogoHub from '@devolutions/icons/react/LogoHub';
import IconLogoDevolutions from '@devolutions/icons/react/LogoDevolutions';

export class LogoMapper {
    private static readonly map: Map<string, FunctionalComponent> = new Map<string, FunctionalComponent>([
        ['windows', IconWindows],
        ['macos', IconApple],
        ['android', IconAndroid],
        ['ios', IconIos],
        ['linux', IconLinux],
        ['rdm', IconLogoRdm],
        ['rdm-mac', IconLogoRdm],
        ['pvm', IconLogoPvm],
        ['dps', IconLogoDps],
        ['hub', IconLogoHub],
        ['cloud', IconLogoDevolutions]
    ]);

    public static getClassNameFromSoftwareOrPlatform(software: string): VNode {
        const icon = this.map.get(software.toLowerCase());
        return icon && h(icon, null);
    }
}
