import * as React from 'preact/compat';
import {Hit} from 'instantsearch.js/es/types';
import {format, formatDistanceToNow, parseISO} from 'date-fns';
import Highlighter from 'react-highlight-words';
import IconBook from '@devolutions/icons/react/Book';
import IconEntryOtp from '@devolutions/icons/react/EntryOtp';

export const BlogHit: React.FC<{
    searchWords: string[],
    hit: Hit,
    dateLocale: Locale
}> = (
    {
        searchWords,
        hit,
        dateLocale
    }) => {

    const formatDate = (date) => {
        return format(parseISO(date), "PPP", { locale: dateLocale });
    }

    const formatDateFromNow = (date) => {
        return formatDistanceToNow(parseISO(date), { locale: dateLocale });
    }

    return (
        <div class="hit blog">
            <a target="_blank" href={hit.url} className="blog__header">
                <img src={hit.thumbnail || 'https://webdevolutions.azureedge.net/blog/default-blog.png'} alt="Blog image" />
            </a>

            <a target="_blank" href={hit.url} className="blog__title title">
                <Highlighter
                    highlightClassName="ais-Highlight-highlighted"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={hit.title}
                />
            </a>

            <div className="blog__subtitle subtitle">
                <IconBook/> {hit.categories.join(' | ')} • {formatDate(hit.publishedDate)}
            </div>

            <div class="blog__content">
                <Highlighter
                    highlightClassName="ais-Highlight-highlighted"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={hit.excerpt}
                />
            </div>

            <div className="blog__footer footer">
                <IconEntryOtp/>
                <span>{formatDateFromNow(hit.modified)}</span>
            </div>
        </div>
    )
};
