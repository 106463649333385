import * as React from 'preact/compat';
import {Hit} from 'instantsearch.js/es/types';
import Highlighter from 'react-highlight-words';
import IconOnlineHelp from '@devolutions/icons/react/OnlineHelp';

export const DocsHit: React.FC<{
    searchWords: string[],
    hit: Hit
}> = (
    {
        searchWords,
        hit,
    }) => {

    return (
        <div className="hit docs">
            <a target="_blank" href={hit.url} className="help__title title">
                <Highlighter
                    highlightClassName="ais-Highlight-highlighted"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={hit.title}
                />
            </a>

            <div className="help__subtitle subtitle">
                <img src={hit.icon} alt={hit.doc}/> {hit.url.replace(/^\/|\/$/g, '').replace(/\//g, ' > ')}
            </div>

            <div className="help__content">
                <Highlighter
                    highlightClassName="ais-Highlight-highlighted"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={hit.content}
                />
            </div>
        </div>
    )
};
